import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { type Country, isCountry } from '../../internal-types/country.type'
import {
  AtlasBodyTitle,
  AtlasH2,
  CardComponent,
  Icon,
  Icons
} from '@justomx/atlas-components'
import { FlexAtom } from 'components/atoms/Flex.atom'

interface CountryData {
  id: Country
  icon: string
}

const BodyTitle = styled(AtlasBodyTitle)`
  display: flex;
  align-items: center;
  gap: 16px;
`

const countries: CountryData[] = [
  { id: 'MX', icon: Icons.IcMexico },
  { id: 'BR', icon: Icons.IcBrazil },
  { id: 'PE', icon: Icons.IcPeru }
]

export type SelectCountryAtomProps = {
  country: string | undefined
  setCountry: (value: Country) => void
}
export function SelectCountryMolecule({
  setCountry,
  country: selectedCountry
}: SelectCountryAtomProps) {
  const { t } = useTranslation('global')
  const onChange = (country: string) => {
    if (isCountry(country)) {
      setCountry(country)
    }
  }

  return (
    <FlexAtom $gap={36}>
      <AtlasH2 style={{ textAlign: 'center' }}>
        {t('select-country.title')}
      </AtlasH2>

      <FlexAtom $gap={16}>
        {countries.map((country) => (
          <CardComponent
            key={country.id}
            $variant={country.id === selectedCountry ? 'selected' : undefined}
            onClick={() => {
              onChange(country.id)
            }}
          >
            <BodyTitle>
              <Icon src={country.icon} /> {t(`select-country.${country.id}`)}
            </BodyTitle>
          </CardComponent>
        ))}
      </FlexAtom>
    </FlexAtom>
  )
}
