import { useEffect, useState } from 'react'
import _ from 'lodash'

const handleMessageRaw = function (setKeys: (value: string) => void) {
  return function handleMessage (msg: MessageEvent<string>) {
    console.log('[Received from scanner]', msg.lastEventId, msg)
    setKeys(msg.data)
  }
}

export const useScanner = () => {
  const [keys, setKeys] = useState<string>('')

  useEffect(() => {
    console.log('[Registering keyboard scanner]')
    const throttledHandleMessage = _.throttle(handleMessageRaw(setKeys), 1000)
    window.addEventListener('message', throttledHandleMessage)

    return function () {
      console.log('[Unregister keyboard scanner]')
      window.removeEventListener('message', throttledHandleMessage)
    }
  }, [setKeys])

  return {
    keys,
    getScan: () => {
      setKeys(() => '')
      return keys
    },
    clearKeys: () => { setKeys('') }
  }
}

export const useScannerReader = (onScan: (value: string) => ((() => void) | void)) => {
  const { keys, getScan, clearKeys } = useScanner()

  useEffect(() => {
    const value = getScan();
    if (value) {
      const clear = onScan(value)
      return clear;
    }
    return;
  }, [keys, getScan]);
}