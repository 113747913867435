import { keycloackAxiosInstance } from '../../utils/keycloack.axios.instance'

type UserPasswordProps = {
  username: string
  password: string
}

export async function loginKeycloackByPassword({
                                                 username,
                                                 password,
                                               }: UserPasswordProps) {
  const requestBody = {
    grant_type: 'password',
    client_id: 'operations-login',
    username,
    password,
  }

  const { data } = await keycloackAxiosInstance().post(
    '/realms/internal/protocol/openid-connect/token',
    requestBody,
    { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

  const accessToken = data.access_token.split('.')[1]
  // Convert the base64 encoded string to a string
  const decoded = Buffer.from(accessToken, 'base64').toString('utf8')
  const payload = JSON.parse(decoded)
  debugger;
  const userInfo = {
    firstName: payload.given_name,
    lastName: payload.family_name,
    username: payload.preferred_username,
    roles: payload.resource_access?.['operations-login']?.roles,
    resourceAccess: payload.resource_access
  }

  return {
    userInfo,
    token: data.access_token,
    refreshToken: data.refresh_token,
    sessionState: data.session_state,
  }
}