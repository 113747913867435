import { loginClientByPassword, loginClientByQR } from './user.client'
import { Country } from '../../internal-types/country.type'
import { LoggedUserDTO } from '@justomx/webview-commons'

export const loginUserService = async (value: string, country: Country): Promise<LoggedUserDTO> => {
  const loginResponse = await loginClientByQR(value, country)
  if (loginResponse.version === 'v1') {
    return loginResponse
  }

  return {
    country,
    authorizationCore: loginResponse.user.saleorAuth.data.tokenCreate.token,
    authorizationWms: loginResponse.token,
    authorization: loginResponse.token,
    warehouse: loginResponse.user.warehouse.name,
    firstName: loginResponse.user.firstName || '.',
    lastName: loginResponse.user.lastName || '.',
    username: loginResponse.user.username,
    roles: []
  }
}

export const loginUserPasswordService = async (username: string, password: string, country: Country): Promise<LoggedUserDTO> => {
  const loginResponse = await loginClientByPassword({
    username,
    password,
    country
  })

  return {
    country,
    authorizationCore: loginResponse.user?.saleorAuth?.data?.tokenCreate?.token || loginResponse.token,
    authorizationWms: loginResponse.token,
    authorization: loginResponse.token,
    warehouse: loginResponse.user.warehouse.name,
    firstName: loginResponse.user.firstName || '.',
    lastName: loginResponse.user.lastName || '.',
    username,
    roles: []
  };
}
