import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasH1,
  ImageThumbnailComponent
} from '@justomx/atlas-components'
import { useScannerReader } from 'hooks/scanner.hook'
import { FlexAtom } from 'components/atoms/Flex.atom'
import loginLogo from '../../../../assets/images/Atlas/login_logo_87x87.png'

interface ScannerProps {
  onScan: (scanned: string) => void
}

export const ScannerMolecule = (props: ScannerProps) => {
  const { t } = useTranslation('global')
  const { onScan } = props

  useScannerReader(onScan)

  return (
    <FlexAtom $gap={16}>
      <FlexAtom $direction="row" $justifyContent="center" $alignItems="center">
        <ImageThumbnailComponent size={87} src={loginLogo} alt="logo" />
      </FlexAtom>

      <AtlasH1 style={{ textAlign: 'center' }}>
        {t('scanner-molecule.title')}
      </AtlasH1>

      <AtlasBodyMain style={{ textAlign: 'center' }}>
        {t('scanner-molecule.use-scanner')}
      </AtlasBodyMain>
    </FlexAtom>
  )
}
