import { useState, useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'

import { UserManagment } from '@justomx/webview-commons'
import { countryToLanguage } from '../App'
import { useGoToCountryPage } from 'pages/home/country/Country.navigator'
import { countryAtom } from 'pages/home/Home.state'
import { saveAndRedirectService } from 'services/redirect/saveAndRedirectService'
import { useGoToHomePage } from 'pages/home/home-list/HomeList.navigator'

export const useInitApp = () => {
  const country = useAtomValue(countryAtom)
  const [loading, setLoading] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)
  const countryPage = useGoToCountryPage()
  const homePage = useGoToHomePage()
  const { i18n } = useTranslation('global')

  useEffect(() => {
    setIsInitialized(true)
  }, [])

  useEffect(() => {
    if (isInitialized) {
      if (!country) {
        countryPage.go()
        setLoading(false)
        return
      }
      const lang = countryToLanguage[country.toLowerCase()]
      setLang(lang)

      try {
        const loggedUser = UserManagment.getCurrentUser()
        if (Object.keys(loggedUser).length) {
          saveAndRedirectService(loggedUser, country)
            .then(() => {})
            .catch((error) => {
              console.error('Error validating current logged user', error)
            })
        } else {
          homePage.go()
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        homePage.go()
        setLoading(false)
      }
    }
  }, [country, isInitialized])

  const setLang = (lang: string) => {
    i18n.changeLanguage(lang).catch((err) => {
      console.error(err)
    })
  }

  return { loading }
}
