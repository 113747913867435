import styled from 'styled-components'

interface FlexAtomProps {
  $gap?: number
  $direction?: string
  $justifyContent?: string
  $alignItems?: string
  $height?: string
}

export const FlexAtom = styled.div<FlexAtomProps>`
  display: flex;
  width: 100%;
  height: ${({ $height }) => $height};
  flex-direction: ${({ $direction }) => $direction ?? 'column'};
  gap: ${({ $gap }) => $gap ?? 8}px;
  justify-content: ${({ $justifyContent }) => $justifyContent};
  align-items: ${({ $alignItems }) => $alignItems};
`
