import { isAxiosError } from 'axios'
import { manageAxiosError, type ServerError } from './axiosError.handler'
import { toast } from 'react-toastify'
import { ErrorType } from 'pages/home/home-list/HomeList.types'

interface LoginError {
  error: string
  translateError: boolean
}

export const manageError = (defaultText: string) => (e: Error) => {
  // TODO: chain of responsabilities or map
  console.error('Error', defaultText, e)
  let message: string | undefined
  if (isAxiosError<ServerError>(e)) {
    message = manageAxiosError(e)
  }

  toast.error(message ?? e.message ?? defaultText)
}

export const isLoginError = (data: unknown): data is LoginError => {
  return (
    !!data &&
    typeof data === 'object' &&
    'error' in data &&
    'translateError' in data
  )
}

export const extractError = (error: any): LoginError => {
  const err: LoginError = {
    error: ErrorType.Unknown,
    translateError: true
  }

  if (typeof error === 'string') {
    err.error = error
  } else if (typeof error === 'object') {
    try {
      err.error = JSON.stringify(error)
      err.translateError = false
    } catch (e) {
      console.warn('Error trying to extract detail from response error', e)
    }
  }

  return err
}
