import {Outlet, useMatches, useNavigation} from 'react-router-dom'
import LoaderAtom from '../../components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'
import {useEffect} from "react";
import { useInitApp } from 'hooks/init-app.hook'

export const HomePage = () => {
  const { loading } = useInitApp()
  const { t } = useTranslation('global')
  const navigation = useNavigation()
  const matches = useMatches()
  const { id } = matches[matches.length - 1]
  const title = id;

  useEffect(() => {
    if (title) {
      document.title = title
    }
  }, [title])


  if (navigation.state === 'loading' || loading) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return <Outlet/>
}
