import { LoggedUserDTO } from '@justomx/webview-commons'
import { loginKeycloackByPassword } from './user-keycloak.client'

export const loginUserKeycloackService = async ({username, password} : {username: string, password: string}): Promise<LoggedUserDTO> => {
  const loginResponse = await loginKeycloackByPassword({
    username,
    password
  })

  const warehouse = loginResponse.userInfo.roles.find((role: string) => role.startsWith('Bodega - '))?.split(' - ')[1]
  const country = loginResponse.userInfo.roles.find((role: string) => role.startsWith('País - '))?.split(' - ')[1]

  return {
    country,
    authorizationCore: loginResponse.token,
    authorizationWms: loginResponse.token,
    authorization: loginResponse.token,
    refreshToken: loginResponse.refreshToken,
    firstName: loginResponse.userInfo.firstName,
    lastName: loginResponse.userInfo.lastName,
    roles: loginResponse.userInfo.roles,
    warehouse: warehouse,
    username: loginResponse.userInfo.username
  }
}