import Axios, { type AxiosInstance } from 'axios'
import { isProduction } from './environment'
import { extractError } from './errors/error.handler'

let axios: AxiosInstance

function configureAxios () {
  if (isProduction()) {
    return Axios.create({
      baseURL: 'https://api.justo.cloud/'
    })
  }

  return Axios.create({
    baseURL: 'https://stag.justo.cloud/'
  })
}

function getAxiosInstance (): AxiosInstance {
  if (!axios) {
    axios = configureAxios()
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const processedError = extractError(error?.response?.data)
        return await Promise.reject(processedError)
      }
    )
  }

  return axios
}

export const newTokenAxiosInstance = getAxiosInstance
