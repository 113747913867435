import { createBrowserRouter } from 'react-router-dom'
import { HomePage } from './home/Home.layout'
import { HomeListPage } from './home/home-list/HomeList.page'
import ErrorPage from './error/Error.page'
import { CountryPage } from './home/country/Country.page'
import { COUNTRY_URL } from './home/country/Country.navigator'
import { HOME_URL } from './home/home-list/HomeList.navigator'

export const router = createBrowserRouter([
  {
    element: <HomePage/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: HOME_URL,
        element: <HomeListPage/>
      },
      {
        path: COUNTRY_URL,
        element: <CountryPage />
      }
    ]
  }

])
