import {
  AtlasBodyMain,
  PrimaryButtonAtom,
  TextBoxMolecule
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { FlexAtom } from 'components/atoms/Flex.atom'
import { isProduction } from '../../../../utils/environment'

type UserAndPassProps = {
  onLogin: (username: string, password: string) => void,
  onLoginKeycloack: (username: string, password: string) => void
}

export function UserAndPassPage({ onLogin, onLoginKeycloack }: UserAndPassProps) {
  const { t } = useTranslation('global')
  const [username, setUsername] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')

  const handleLogin = () => {
    onLogin(username, password)
  }

  const handleLoginKeycloack = () => {
    onLoginKeycloack(username, password)
  }

  return (
    <FlexAtom $gap={22}>
      <AtlasBodyMain style={{ textAlign: 'center' }}>
        {t('home.login-with-password')}
      </AtlasBodyMain>
      <TextBoxMolecule
        tabIndex={-1}
        type="email"
        label={t('home.user')}
        onChange={(value) => {
          setUsername(value)
        }}
      />
      <TextBoxMolecule
        tabIndex={-1}
        type="password"
        label={t('home.password')}
        onChange={(value) => {
          setPassword(value)
        }}
      />
      <PrimaryButtonAtom onClick={handleLogin} type='button'>
        {t('home.login')}
      </PrimaryButtonAtom>
      {!isProduction() && <PrimaryButtonAtom onClick={handleLoginKeycloack} type='button'>
        {t('home.login-with-keycloack')}
      </PrimaryButtonAtom> }
    </FlexAtom>
  )
}
