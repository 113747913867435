import {Icon} from './Icon'
import styled from "styled-components";

export const InputIconAtom = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(100%) saturate(900%) hue-rotate(-28deg);
`
