import { Country } from '../../internal-types/country.type'
import { isProduction } from '../../utils/environment'
import { LoggedUserDTO, UserManagment } from '@justomx/webview-commons'

const REDIRECT_URLS = {
  production: 'https://atlas-web.pages.dev/picking',
  staging: 'https://feat-new-login.atlas-web.pages.dev/picking'
}

export const saveAndRedirectService = async (value: LoggedUserDTO, country: Country): Promise<void> => {
  UserManagment.setCurrentUser(
    {
      ...value,
      country: value.country.toLowerCase()
    })

  if (isProduction()) {
    window.location.href = REDIRECT_URLS.production + '?userFrom=getUserInfo&country=' + country
  } else {
    window.location.href = REDIRECT_URLS.staging + '?userFrom=getUserInfo&country=' + country
  }
}
