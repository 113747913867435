import {LoggedUser, LoggedUserDTO} from "./user-managment.types";

declare global {
  interface Window {
    WV: {
      saveUserInfo: (user: string) => void;
      getUserInfo: () => string;
      logout: () => void;
    };
  }
}

const setCurrentUser = (user: LoggedUserDTO) => {
  const userParsed = new LoggedUser(user);
  window.WV.saveUserInfo(userParsed.serialize());
};

const getCurrentUser = (): LoggedUser => {
  const rawUserInfo = window.WV.getUserInfo();

  return LoggedUser.deserialize(rawUserInfo);
};

const logout = () => {
  window.WV?.saveUserInfo('');
  window.WV.logout();
};

export const UserManagment = {
  setCurrentUser,
  getCurrentUser,
  logout,
};
