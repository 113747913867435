const prodUrls = ['https://operations-login.pages.dev/', 'https://operations-login.justo.cloud/']

export const isProduction = () => {
  const environment = new URLSearchParams(window.location.search).get('environment');
  if (!environment)
    return prodUrls.some(e => location.href.startsWith(e))
  return environment === 'production'
}

export const isDevMode = () => {
  const mode = new URLSearchParams(window.location.search).get('mode');
  return mode === 'dev'
}
