import { newTokenAxiosInstance } from '../../utils/new-token.axios.instance'
import { Country } from '../../internal-types/country.type'
import { keycloackAxiosInstance } from '../../utils/keycloack.axios.instance'

export async function loginClientByQR(value: string, country: string) {
  const axios = newTokenAxiosInstance()
  const response = await axios.post('/one-stop-checkin-bff/api/login', {
    otp: value
  }, {
    headers: {
      'x-justo-country': country.toUpperCase()
    }
  })
  return response.data
}

type LoginClientByPasswordProps = {
  username: string
  password: string
  country: Country
}

export async function loginClientByPassword({
                                              username,
                                              password,
                                              country
                                            }: LoginClientByPasswordProps) {
  const axios = newTokenAxiosInstance()
  const response = await axios.post('/user-manager-bff/api/login', {
    username,
    password
  }, {
    headers: {
      'x-justo-country': country.toUpperCase()
    }
  })

  return response.data
}
