import {analytics} from "./analytics.utils";

const VERSION = 'v0.0.1';

const errorHandler = (e: unknown) => {
  console.error("Error calling analytic event", e);
}

interface LoginEvent {
  hasPassword?: boolean
  warehouse?: string
  username: string
  country: string
  isQr: boolean
}

const login = (event: LoginEvent) => {
  analytics.track('Login - Login succeded', {
    VERSION,
    username: event.username,
    country: event.country,
    isQr: event.isQr,
    hasPassword: Boolean(event.hasPassword),
    warehouse: event.warehouse,
  }).catch(errorHandler)
}

const tryLogin = (event: LoginEvent) => {
  analytics.track('Login - Try login', {
    VERSION,
    username: event.username,
    hasPassword: Boolean(event.hasPassword),
    country: event.country,
    isQr: event.isQr,
  }).catch(errorHandler)
}

const errorLogin = (event: LoginEvent, error: string) => {
  analytics.track('Login - Error login', {
    VERSION,
    username: event.username,
    hasPassword: Boolean(event.hasPassword),
    country: event.country,
    isQr: event.isQr,
    error,
  }).catch(errorHandler)
}

export const Analytics = {
  login,
  tryLogin,
  errorLogin
}
