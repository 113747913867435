import Axios, { type AxiosInstance } from 'axios'
import { isProduction } from './environment'

let axios

function configureAxios () {
  if (isProduction()) {
    return Axios.create({
      baseURL: 'http://localhost:8080/'
    })
  }

  return Axios.create({
    baseURL: 'https://auth.dev.justo.cloud/'
  })
}

function getAxiosInstance (): AxiosInstance {
  if (!axios) {
    axios = configureAxios()
  }

  return axios
}

export const keycloackAxiosInstance = getAxiosInstance
