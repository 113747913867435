import styled from 'styled-components'
import { AtlasCaption } from '../atoms/Typography/Typography.atom'
import React, { type ChangeEventHandler, type HTMLInputTypeAttribute, useEffect, useState } from 'react'
import { InputIconAtom } from '../atoms/icons/InputIcon.atom'
import { Icons } from '../atoms/icons/Icons'
import { AtlasColor } from '../foundations/Colors.foundations'

interface TextBoxAtomProps {
  label: string
  onNewValue?: (value: string) => void
  onChange?: (value: string) => void
  onChangeFullEvent?: (value: React.ChangeEvent<HTMLInputElement>) => void
  onCancel?: () => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  type?: HTMLInputTypeAttribute
  "data-test"?: string
  name?: string
  value?: string
  hasErrors?: boolean
  autocomplete?: string
  tabIndex?: number
}

const Input = styled.input<{ $hasErrors?: boolean }>`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px;
  gap: 8px;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid
    ${({ $hasErrors }) =>
      $hasErrors ? AtlasColor.Error : AtlasColor.Neutral20};
  color: ${AtlasColor.Neutral20};

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration { 
    display: none; 
  }

  &:focus {
    outline: none;
    border: 1px solid
      ${({ $hasErrors }) =>
        $hasErrors ? AtlasColor.Error : AtlasColor.Neutral00};
    color: ${AtlasColor.Neutral00};
  }

  &:not(:placeholder-shown) + label {
    top: -6px;
    font-size: 12px;
    padding: 0 4px;
    background-color: ${AtlasColor.Background};
    color: ${({ $hasErrors }) =>
      $hasErrors ? AtlasColor.Error : AtlasColor.Neutral00};
  }

  &::placeholder {
    color: ${AtlasColor.Neutral20};
  }
`
const Label = styled.label<{ $hasErrors: boolean }>`
  color: ${({ $hasErrors }) =>
    $hasErrors ? AtlasColor.Error : AtlasColor.Neutral20};
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 18px;
  transition: 0.2s ease all;

  ${Input}:focus ~ & {
    top: -6px;
    font-size: 12px;
    padding: 0 4px;
    background-color: ${AtlasColor.Background};
    color: ${({ $hasErrors }) =>
      $hasErrors ? AtlasColor.Error : AtlasColor.Neutral00};
  }
`

const TextBoxWrapper = styled.div`
  position: relative;
  width: 100%;
`

const onKeyUp = (onNewValue?: (value: string) => void) => (event: React.KeyboardEvent<HTMLInputElement>) => {
  return (event.currentTarget.value) &&
  (event.key === 'Enter' || event.keyCode === 13)
    ? onNewValue?.(event.currentTarget.value)
    : null
}

type SearchIconProps = {
  hasValue: boolean
  onCancel: () => void
  "data-test"?: string
}

const SearchIcon = (props: SearchIconProps) => {
  const { hasValue, onCancel } = props;
  const Icon = hasValue ? Icons.IcCancelIcon : Icons.IcSearch24dpIcon

  return <InputIconAtom
    onClick={hasValue ? onCancel : undefined}
    src={Icon}
    size={18}
    data-test={props["data-test"]}
  />
}

export const TextBoxMolecule = (props: TextBoxAtomProps) => {
  const {
    label,
    onNewValue,
    onChange,
    onChangeFullEvent,
    onBlur,
    onClick,
    onFocus,
    type = 'text',
    hasErrors = false,
    autocomplete,
    tabIndex
  } = props;

  const [value, setValue] = useState<string>(props.value ?? '')
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false)
  const _keyUp = onKeyUp(onNewValue)

  useEffect(() => {
    setValue(props.value ?? '')
  }, [props.value])

  const _change: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(() => event.target.value)
    onChange?.(event.target.value)
    onChangeFullEvent?.(event)
  }

  const onCancel = () => {
    setValue(() => '')
    onChange?.('')
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState)
  }

  const inputMode = type === 'number' ? { inputMode: 'numeric' } as const : {}
  const inputType = type === 'password' && isPasswordVisible ? 'text' : type
  const viewIcon = isPasswordVisible ? Icons.IcUnViewIcon : Icons.IcViewIcon

  return <TextBoxWrapper>
    <Input placeholder=""
           type={inputType}
           onKeyUp={_keyUp}
           onChange={_change}
           onBlur={onBlur}
           onClick={onClick}
           onFocus={onFocus}
           value={value}
           data-test={props['data-test']}
           name={props.name}
           autoComplete={autocomplete}
           tabIndex={tabIndex}
           $hasErrors={hasErrors}
           {...inputMode}
    />
    <Label $hasErrors={hasErrors}>
      <AtlasCaption
        data-test={props['data-test'] + '-label'}>{label}</AtlasCaption>
    </Label>
    {type === 'search' && <SearchIcon
        hasValue={Boolean(value)}
        onCancel={onCancel}
        data-test={props['data-test'] + '-search-icon'}
    /> }
    {type === 'password' && <InputIconAtom
      onClick={togglePasswordVisibility}
      src={viewIcon}
      size={18}
      data-test={props['data-test']}
    />}
  </TextBoxWrapper>
}
