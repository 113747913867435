{
  "loader": {
    "message": "Aguarde, por favor..."
  },
  "common": {
    "continue": "Continuar"
  },
  "home": {
    "invalid-password": "Usuário o Senha inválida",
    "invalid-qr": "Código QR inválido",
    "unknown-error": "Erro desconhecido",
    "user-not-found": "El usuario no existe",
    "user-login": "Usuario o contraseña incorrectos o usuario no existe",
    "user": "Usuário",
    "password": "Senha",
    "login-with-qr": "Entrar com código QR",
    "login-with-password": "ou entre com e-mail e senha",
    "login": "Entrar",
    "login-with-keycloack": "Entrar com Keycloack"
  },
  "receipt": {
    "title": "Recibo"
  },
  "scanner-molecule": {
    "title": "Entre com código QR",
    "use-scanner": "Entre na tablet procurando seu nome e escaneie o código com seu handheld."
  },
  "select-country": {
    "title": "Selecione o país",
    "MX": "México",
    "BR": "Brasil",
    "PE": "Peru"
  }
}