import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import type { Country } from 'internal-types/country.type'
import { countryAtom } from '../Home.state'
import { useGoToHomePage } from '../home-list/HomeList.navigator'
import { countryToLanguage } from '../../../App'
import { useState } from 'react'

export const useCountry = () => {
  const { i18n } = useTranslation('global')
  const setCountryStorage = useSetAtom(countryAtom)
  const [country, setCountry] = useState<Country>()
  const homePage = useGoToHomePage()

  const onContinue = () => {
    setCountryStorage(country)
    homePage.go()
  }

  const onSelectCountry = (country: Country) => {
    setCountry(country)
    i18n
      .changeLanguage(countryToLanguage[country.toLowerCase()])
      .catch((err) => {
        console.error(err)
      })
  }

  return { country, actions: { onSelectCountry, onContinue } }
}
