import { useNavigate } from 'react-router-dom'

export const useGoToHomePage = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(HOME_URL)
    }
  }
}

export const HOME_URL = '/'
