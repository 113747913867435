import { useTranslation } from 'react-i18next'
import {
  BottomGroupMolecule,
  PageContentAtom,
  PrimaryButtonAtom
} from '@justomx/atlas-components'
import { SelectCountryMolecule } from 'design-system/molecule/SelectCountry.molecule'
import { useCountry } from './Country.hook'
import styled from 'styled-components'

const PageContent = styled(PageContentAtom)`
  padding: 40px 16px;
`

export const CountryPage = () => {
  const { t } = useTranslation('global')
  const { actions, country } = useCountry()

  return (
    <PageContent>
      <SelectCountryMolecule
        country={country}
        setCountry={actions.onSelectCountry}
      />

      <BottomGroupMolecule>
        <PrimaryButtonAtom
          type="button"
          onClick={actions.onContinue}
          disabled={!country}
        >
          {t('common.continue')}
        </PrimaryButtonAtom>
      </BottomGroupMolecule>
    </PageContent>
  )
}
